$textGrey: #808080;
$darkGrey: #333333;
$header: 160px;
$headerMobile: 100px;

$unit: 20px;

$mobile: 767px;
$gridWidth: 1140px;
$bodyWidth: 1440px;

$sans: "Avenir Pro", "Avenir", "Avenir Next", "Helvetica Neue", sans-serif;
$serif: "Playfair Display", serif;

.AvenirLTPro-Light {
  font-family: AvenirLTPro-Light, $sans;
  font-weight: normal;
  font-style: normal;
}
.AvenirLTPro-Roman {
  font-family: AvenirLTPro-Roman, $sans;
  font-weight: normal;
  font-style: normal;
}
.AvenirLTPro-Heavy {
  font-family: AvenirLTPro-Heavy, $sans;
  font-weight: normal;
  font-style: normal;
}

$font300: AvenirLTPro-Light, $sans;
$font400: AvenirLTPro-Roman, $sans;
$font700: AvenirLTPro-Heavy, $sans;

@import "scss/vars";
@import "scss/mixins";
@import "scss/hamburger";

@import "~glightbox/dist/css/glightbox.css";

// http://hugeinc.github.io/flexboxgrid-sass/

$flexboxgrid-breakpoints: sm 48rem,
  // ipad portrait
    md 64rem,
  // ipad landscape
    lg 80rem,
  // 1280
    xlg 90rem; // 1440

$grid-columns: 12;
$gutter-width: 50px;
$outer-margin: 25px;

@import "~flexboxgrid-sass/flexboxgrid";

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: $font400;
  * {
    box-sizing: inherit;
  }
}

body {
  max-width: $bodyWidth;
  margin: 0 auto;
  @include tablet {
    margin-top: 110px;
    margin-bottom: 110px;
  }
}

h1,
h2,
h4 {
  font-family: $serif;
  font-weight: 400;
  b {
    font-weight: 700;
  }
}

h2 {
  @include fp(font-size, 20, 28);
}

h3 {
  font-family: $font700;
  color: $textGrey;
}

h4 {
  @include fp(font-size, 16, 24);
  color: #b3b3b3;
  line-height: 1.3;
  margin-top: 4rem;
  &.brands {
    color: rgba(#000, 0.8);
    letter-spacing: 2px;
    font-size: 36px;
  }
}

p {
  @include fp(font-size, 14, 16);
  line-height: 1.6;
  margin-bottom: 2em;
}

a {
  color: $textGrey;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin-bottom: 3em;
  li {
    margin-bottom: 1em;
  }
}

img {
  width: 100%;
  max-width: 100%;
}

.spacer {
  height: 5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hidden-tablet,
.hidden-desktop {
  @include tablet {
    display: none !important;
  }
}

.hidden-mobile {
  @include mobile {
    display: none !important;
  }
}

.mobilePadding {
  @include mobile {
    padding-left: 50px;
    padding-right: 50px;
  }
}



.textGrey,
.contentWrapper {
  ul {
    position: relative;
    padding-left: 2rem;
    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 2px;
      top: 0;
      bottom: 0;
      background: #d8d8d8;
      left: 0;
    }
  }
}

.lightGrey {
  background: #fafafa;
}

.logo{
  max-width: 128px;
  img{
    max-width: 128px;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.hero{
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
}

@import "scss/cms";
